import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { getSpaceAttributesFromSpace, SpacesService, SpaceType, UiRouterStateSpaceAttributes } from '../../spaces';
import { select, Store } from '@ngrx/store';
import * as fromRoot from 'account-hybrid/store/reducers/index';
import * as fromSpaces from '../../spaces/store/reducers/spaces.reducer';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StateService } from '@uirouter/core';
import { Company, IdentityService, LicenseType, Property, SessionService } from 'account-hybrid/core/authentication';
import { SetIsVisible } from '../../spaces/store/actions/spaces.actions';
import { ExperienceLicensePermission } from 'account-hybrid/features/experiences/enums/experience-license-permission.enum';


@Component({
    selector: 'ft-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {
    @Input() spacing = false;
    @Input() sticky = false;
    @Input() logoVisible = true;
    @Input() menuVisible = true;
    @Input() sideMenuVisible = true;
    @Input() modifierClass: string | string[];
    spaces$: Observable<fromSpaces.State>;
    spacesNumber: number;
    isFliptoStaff: boolean;
    isCompanyContextAvailable: boolean;
    isCollaborativeSpace: boolean;
    isPropertySpace = this.spacesService.isPropertySpace();
    spaceAttributes: UiRouterStateSpaceAttributes;
    company: Company;
    property: Property;

    constructor(public spacesService: SpacesService,
                private sessionService: SessionService,
                public identityService: IdentityService,
                public store: Store<fromRoot.State>,
                private cdr: ChangeDetectorRef,
                public stateService: StateService) {
        this.isFliptoStaff = this.identityService.isFliptoStaff();
        this.isCompanyContextAvailable = !!this.spacesService.getCompanyUuid() && this.spacesService.current.type !== SpaceType.Collaborative;
        this.isCollaborativeSpace = !!this.spacesService.getCompanyUuid() && this.spacesService.current.type === SpaceType.Collaborative;
        this.company = this.sessionService.getCompany(this.spacesService.getCompanyUuid());
        this.property = this.sessionService.getProperty(this.spacesService.getPropertyUuid());
        this.sessionService.licenseChanged$
            .subscribe(() => {
                this.property = this.sessionService.getProperty(this.spacesService.getPropertyUuid());
                this.cdr.markForCheck();
            });
    }

    ngOnInit(): void {
        this.spaces$ = this.store.pipe(select(fromRoot.selectSpaces));
        this.spaces$.pipe(tap(state => this.spacesNumber = (state.allSpaces.length + state.collaborativeSpaces.length))).subscribe();
        if (this.spacesService.current) {
            this.spaceAttributes = getSpaceAttributesFromSpace(this.spacesService.current);
        }
    }

    spaceClicked() {
        if (this.spacesNumber > 1) {
            this.store.dispatch(new SetIsVisible(true));
        } else {
            this.stateService.go('hub', getSpaceAttributesFromSpace(this.spacesService.current));
        }
    }

    isLocalLicenseActive() {
        return ((this.property?.LicensesExtended?.find(license => license.LicenseId === LicenseType.Experiences)?.Permissions & ExperienceLicensePermission.Local) != 0);
    }

    isOGLicenseActive() {
        return ((this.property?.LicensesExtended?.find(license => license.LicenseId === LicenseType.Experiences)?.Permissions & ExperienceLicensePermission.AllOG) != 0);
    }
}
