import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExperimentResponse } from '../models/experiment.model';
import { ExperimentStatsResponse } from '../models/experiment-stats.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import { DateRange } from 'apps/account/src/account-shared';
import { Environment, ENVIRONMENT_TOKEN } from 'account-hybrid/core/environment.service';
import {
    DiscoveryConversionReport,
    DiscoveryRangesStatistics
} from 'account-hybrid/common/components/discovery/models';
import {
    DiscoveryEmailStatsResponse,
    DiscoveryTrafficStats,
    DiscoveryTrafficStatsResponse,
    PlannerStatsResponse
} from 'account-hybrid/common/components/discovery/interfaces';


@Injectable({ providedIn: 'root' })
export class ExperimentService {
    isPropertyTypeSpace = this.spacesService.isPropertySpace();

    constructor(
        private http: HttpClient,
        private spacesService: SpacesService,
        @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
    }

    // discovery could be visited in all space types
    getContextUuid() {
        return this.spacesService.getCompanyUuid() ||
            this.spacesService.getPropertyUuid();
    }

    getPlannerStats(dateRange: DateRange): Observable<PlannerStatsResponse> {
        const url = this.isPropertyTypeSpace || this.spacesService.isMixedSpace()
            ? `${this.environment.apiBaseUrl}/${this.spacesService.getPropertyUuid()}/properties/${this.spacesService.getPropertyUuid()}/discovery/overview`
            : `${this.environment.apiBaseUrl}/${this.spacesService.getCompanyUuid()}/company/${this.spacesService.getCompanyUuid()}/discovery/overview`;
        const params = dateRange ? dateRange.toParams() : {};
        return this.http.get<PlannerStatsResponse>(url, { params, headers: new HttpHeaders().set('cached', 'true') });
    }

    getPlannerStatsByComponentGroup(componentGroupUuid: string, dateRange: DateRange): Observable<PlannerStatsResponse> {
        const url = this.isPropertyTypeSpace || this.spacesService.isMixedSpace()
            ? `${this.environment.apiBaseUrl}/${this.spacesService.getPropertyUuid()}/properties/${this.spacesService.getPropertyUuid()}/discovery/${componentGroupUuid}/overview`
            : `${this.environment.apiBaseUrl}/${this.spacesService.getCompanyUuid()}/company/${this.spacesService.getCompanyUuid()}/discovery/${componentGroupUuid}/overview`;
        const params = dateRange ? dateRange.toParams() : {};
        return this.http.get<PlannerStatsResponse>(url, { params, headers: new HttpHeaders().set('cached', 'true') });
    }

    getPropertiesPerformanceStats(dateRange: DateRange): Observable<PlannerStatsResponse[]> {
        const url = `${this.environment.apiBaseUrl}/${this.getContextUuid()}/company/${this.getContextUuid()}/discovery/properties/overview`;
        const params = dateRange ? dateRange.toParams() : {};
        return this.http.get<PlannerStatsResponse[]>(url, { params });
    }

    getTrafficStats(componentGroupUuid: string, dateRange: DateRange): Observable<DiscoveryTrafficStatsResponse> {
        const url = `${this.environment.apiBaseUrl}/${this.getContextUuid()}/discovery/${componentGroupUuid}/overview/users`;
        const params = dateRange ? dateRange.toParams() : {};
        return this.http.get<DiscoveryTrafficStats>(url, { params, headers: new HttpHeaders().set('cached', 'true') });
    }

    getEmailStats(componentGroupUuid: string, dateRange: DateRange): Observable<DiscoveryEmailStatsResponse> {
        const url = this.isPropertyTypeSpace || this.spacesService.isMixedSpace()
            ? `${this.environment.apiBaseUrl}/${this.spacesService.getPropertyUuid()}/properties/${this.spacesService.getPropertyUuid()}/discovery/${componentGroupUuid}/emails/overview`
            : `${this.environment.apiBaseUrl}/${this.spacesService.getCompanyUuid()}/company/${this.spacesService.getCompanyUuid()}/discovery/${componentGroupUuid}/emails/overview`;
        const params = dateRange ? dateRange.toParams() : {};
        return this.http.get<DiscoveryEmailStatsResponse>(url, { params });
    }

    getTimelineStats(dateRange: DateRange): Observable<DiscoveryRangesStatistics> {
        const url = this.isPropertyTypeSpace || this.spacesService.isMixedSpace()
            ? `${this.environment.apiBaseUrl}/${this.spacesService.getPropertyUuid()}/properties/${this.spacesService.getPropertyUuid()}/discovery/active-conversations/overview`
            : `${this.environment.apiBaseUrl}/${this.spacesService.getCompanyUuid()}/company/${this.spacesService.getCompanyUuid()}/discovery/active-conversations/overview`;
        const params = dateRange ? dateRange.toParams() : {};
        return this.http.get<DiscoveryRangesStatistics>(url, { params, headers: new HttpHeaders().set('cached', 'true') });
    }

    getExperiments(componentGroupUuid?: string): Observable<ExperimentResponse[]> {
        const url = this.isPropertyTypeSpace || this.spacesService.isMixedSpace()
            ? `${this.environment.apiBaseUrl}/${this.spacesService.getPropertyUuid()}/properties/${this.spacesService.getPropertyUuid()}/${componentGroupUuid ? `${componentGroupUuid}/` : ''}experiments`
            : `${this.environment.apiBaseUrl}/${this.spacesService.getCompanyUuid()}/company/${this.spacesService.getCompanyUuid()}/${componentGroupUuid ? `${componentGroupUuid}/` : ''}experiments`;
        return this.http.get<ExperimentResponse[]>(url, { headers: new HttpHeaders().set('cached', 'true') });
    }

    getStats(componentGroupUuid: string, dateRange: DateRange): Observable<ExperimentStatsResponse[]> {
        const url = `${this.environment.apiBaseUrl}/${this.getContextUuid()}/discovery/${componentGroupUuid}/experiments/statistics`;
        const params = dateRange ? dateRange.toParams() : {};
        return this.http.get<ExperimentStatsResponse[]>(
            url, { params });
    }

    getDiscoveryConversionReport(componentGroupUuid: string, dateRange: DateRange): Observable<DiscoveryConversionReport> {
        const url = `${this.environment.apiBaseUrl}/discovery/${componentGroupUuid}/experiment/conversions`;
        const params = dateRange ? dateRange.toParams() : {};
        return this.http.get<DiscoveryConversionReport>(url, { params });
    }
}
