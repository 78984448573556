import {
    APP_BASE_HREF,
    CommonModule,
    Location,
    LocationStrategy,
    PathLocationStrategy,
    registerLocaleData
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import {
    APP_INITIALIZER,
    InjectionToken,
    Injector,
    LOCALE_ID,
    NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { downgradeInjectable, UpgradeModule } from '@angular/upgrade/static';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { ClipboardModule } from 'ngx-clipboard';
import { environment } from '../environments/environment';
import { APP_MODULE } from './angularjs.module';
import { AppComponent } from './app.component';
import {
    APP_MENU_AREAS_REGISTRY,
    AREAS_REGISTRY,
    downgradeForAngularJS,
    STATE_PROVIDER
} from './config/bootstrap-angularjs.config';
import {
    ADMIN_STATES,
    COLLABORATIVE_COMPANY_SPACE_STATES,
    COMPANY_SPACE_STATES,
    GLOBAL_STATES,
    MIXED_SPACE_STATES,
    PROPERTY_SPACE_STATES
} from './config/states.config';
import { metaReducers, reducers } from './store/reducers';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import {
    AreasRegistry,
    DownloadService,
    FileSaverService,
    ModalService,
    MultipleOptionsRegistryItem,
    AccountSharedModule,
    StateRegistryItem
} from 'apps/account/src/account-shared';
import { Environment, ENVIRONMENT_TOKEN } from './core/environment.service';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ParamInterceptor } from './core/interceptors/param.interceptor';
import { HttpXsrfInterceptor } from './core/interceptors/xsrf.interceptor';
import { FeatureFlagsService, IdentityService, Permissions, SessionService } from './core/authentication';
import { FormsModule } from '@angular/forms';
import { AccountCommonModule } from './common/common.module';
import { getSpaceAttributesFromSpace, SpacesService, SpaceType } from './common/components/spaces';
import { IconsService } from './common/services/icons.service';
import { ShortUrlsModule } from './downgraded/short-urls/short-urls.module';
import { StateRegistry, trace } from '@uirouter/core';
import { PasswordStrengthModule } from './common/modules/password-strength/password-strength.module';
import { AudienceOnboardService } from './common/modules/audience-onboard/services/audience-onboard.service';
import { PlatformStateService } from './core/platform-state.service';
import { LicenseService } from './core/license.service';
import { AuthenticationModule } from './features/authentication/authentication.module';
import { EntityDataModule } from '@ngrx/data';
import { rootEffects } from 'account-hybrid/store/effects';
import { ContextSelectorModule } from 'account-hybrid/features/context-selector/context-selector.module';
import * as browserUpdate from 'browser-update';
import { CacheInterceptor } from 'account-hybrid/core/interceptors/cache.interceptor';
import { VendorsDataService } from "account-hybrid/features/experiences/services/vendors-data.service";
import { RootStoresModule } from 'account-hybrid/store/root-stores.module';

trace.enable(1, 5);
registerLocaleData(localeEn, 'en', localeEnExtra);

declare let ENV, LOGIN_URL, API_BASE_URL, API_V1_BASE_URL, V1_BASE_URL, ACE_BASE_PATH, V1_HOME_PAGE_URL,
    GOOGLE_CHARTS_MAP_API_KEY, ACCOUNT_VERSION;
declare let FliptoHubDomain, FliptoBaseHelpCenterUrl, FliptoPlatformDomain, AzureCdnBaseUrl, CurationShareContentSize;
declare const environmentSettings;

declare const angular, ace;

export const CNAME_URL_TOKEN = new InjectionToken('cnameUrl');
export const LOCAL_STORAGE_TOKEN = new InjectionToken('localStorage');
export const INJECTOR_TOKEN = new InjectionToken('$injector');
export const URL_FORMATTER_TOKEN = new InjectionToken('urlFormatter');
export const FT_CACHE_TOKEN = new InjectionToken('ftCache');
export const USER_API_TOKEN = new InjectionToken('User');
export const LOCATION_TOKEN = new InjectionToken('$location');
export const NGREDUX = new InjectionToken('ngredux');
export const FT_MODAL = new InjectionToken('ftModal');


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        UpgradeModule,
        CommonModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN',
        }),
        FormsModule,
        UIRouterUpgradeModule.forRoot({
            states: GLOBAL_STATES
        }),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false
            }
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        EffectsModule.forRoot(rootEffects),
        EntityDataModule.forRoot({}),
        RootStoresModule,
        ShortUrlsModule,
        NgSelectModule,
        MatIconModule,
        ClipboardModule,
        AccountSharedModule,
        AccountCommonModule,
        ContextSelectorModule,
        AuthenticationModule,
        PasswordStrengthModule.forRoot({
            minLength: 6,
            minNumbersLength: 1,
            minSpecialsLength: 1,
            minUppercaseLength: 1,
            minLowercaseLength: 1,
            numberOrSpecials: true,
            noSpaces: true
        })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: function (httpClient: HttpClient, identityService: IdentityService) {
                return () => {
                    return new Promise<void>((resolve) => {
                        if (!identityService.isAnonymous()) {
                            httpClient.get('/api/auth/ping')
                                .subscribe(() => resolve);
                        }
                        resolve(null);
                    });
                };
            },
            deps: [HttpClient, IdentityService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: function (platformStateService: PlatformStateService, identityService: IdentityService, spacesService: SpacesService) {
                return () => {
                    return new Promise<void>((resolve) => {
                        if (!identityService.isAnonymous() && spacesService.hasPropertyContext()) {
                            const propertyUuid = spacesService.getPropertyUuid();
                            platformStateService.load(propertyUuid)
                                .then((data) => {
                                platformStateService.state = data;
                            })
                                //@ts-ignore
                                .finally(resolve);
                        }
                        resolve(null);
                    });
                };
            },
            deps: [PlatformStateService, IdentityService, SpacesService],
            multi: true
        },
        { provide: APP_BASE_HREF, useValue: '/' },
        {
            provide: ENVIRONMENT_TOKEN, useFactory: () => {
                return {
                    name: environmentSettings.ENV || ENV,
                    loginUrl: environmentSettings.LOGIN_URL || LOGIN_URL,
                    apiBaseUrl: environmentSettings.API_BASE_URL || API_BASE_URL,
                    apiV1BaseUrl: environmentSettings.API_V1_BASE_URL || API_V1_BASE_URL,
                    v1BaseUrl: environmentSettings.V1_BASE_URL || V1_BASE_URL,
                    v1HomePageUrl: environmentSettings.V1_HOME_PAGE_URL || V1_HOME_PAGE_URL,
                    googleChartsMapApiKey: environmentSettings.GOOGLE_CHARTS_MAP_API_KEY || GOOGLE_CHARTS_MAP_API_KEY,
                    aceBasePath: environmentSettings.ACE_BASE_PATH || ACE_BASE_PATH,
                    FliptoHubDomain: environmentSettings.FliptoHubDomain || FliptoHubDomain,
                    FliptoBaseHelpCenterUrl: environmentSettings.FliptoBaseHelpCenterUrl || FliptoBaseHelpCenterUrl,
                    FliptoPlatformDomain: environmentSettings.FliptoPlatformDomain || FliptoPlatformDomain,
                    AzureCdnBaseUrl: environmentSettings.AzureCdnBaseUrl || AzureCdnBaseUrl,
                    CurationShareContentSize: environmentSettings.CurationShareContentSize || CurationShareContentSize,
                    version: environmentSettings.ACCOUNT_VERSION || ACCOUNT_VERSION
                } as Environment;
            }
        },
        { provide: 'ENVIRONMENT_TOKEN', useExisting: ENVIRONMENT_TOKEN },
        { provide: FT_MODAL, useFactory: (i) => i.get('ftModal'), deps: ['$injector'] },
        { provide: AREAS_REGISTRY, useClass: AreasRegistry, deps: [] },
        { provide: 'AreasRegistry', useExisting: AREAS_REGISTRY },
        { provide: APP_MENU_AREAS_REGISTRY, useClass: AreasRegistry, deps: [] },
        { provide: 'AppMenuAreasRegistry', useExisting: APP_MENU_AREAS_REGISTRY },
        // { provide: MOBILE_MENU_AREAS_REGISTRY, useClass: AreasRegistry, deps: [] },
        // { provide: 'MobileMenuAreasRegistry', useExisting: MOBILE_MENU_AREAS_REGISTRY },
        {
            provide: CNAME_URL_TOKEN,
            useFactory: $injector => {
                const $filter = $injector.get('$filter');
                return $filter('cnameUrl');
            },
            deps: ['$injector']
        },
        {
            provide: LOCAL_STORAGE_TOKEN,
            useFactory: $injector => $injector.get('localStorage'),
            deps: ['$injector']
        },
        {
            provide: INJECTOR_TOKEN,
            useFactory: $injector => $injector,
            deps: ['$injector']
        },
        {
            provide: URL_FORMATTER_TOKEN,
            useFactory: $injector => $injector.get('urlFormatter'),
            deps: ['$injector']
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ParamInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpXsrfInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true
        },
        { provide: STATE_PROVIDER, useFactory: ($injector) => $injector.get('$stateProvider'), deps: ['$injector'] },
        {
            provide: FT_CACHE_TOKEN,
            useFactory: $injector => $injector.get('ftCache'),
            deps: ['$injector']
        },
        {
            provide: USER_API_TOKEN,
            useFactory: $injector => $injector.get('User'),
            deps: ['$injector']
        },
        {
            provide: LOCATION_TOKEN,
            useFactory: $injector => $injector.get('$location'),
            deps: ['$injector']
        },
        {
            provide: NGREDUX,
            useFactory: $injector => $injector.get('$ngRedux'),
            deps: ['$injector']
        },
        {
            provide: '$ocLazyLoad',
            useFactory: $injector => $injector.get('$ocLazyLoad'),
            deps: ['$injector']
        },
        {
            provide: '$ngReduxProvider',
            useFactory: $injector => $injector.get('$ngReduxProvider'),
            deps: ['$injector']
        },
        { provide: LOCALE_ID, useValue: 'en' },
        Location, { provide: LocationStrategy, useClass: PathLocationStrategy },
    ]
})
export class AppModule {
    constructor(
        private modalService: ModalService,
        private upgrade: UpgradeModule,
        private injector: Injector,
        private icons: IconsService
    ) {
        this.modalService.onModalRequested.subscribe(id => {
            (this.injector.get(FT_MODAL) as any).show(id);
        });
    }

    async ngDoBootstrap() {

        this.icons.register();
        APP_MODULE.factory('ngrxStore', downgradeInjectable(Store));
        APP_MODULE.factory('SessionService', downgradeInjectable(SessionService));
        APP_MODULE.factory('IdentityService', downgradeInjectable(IdentityService));
        APP_MODULE.factory('SpacesService', downgradeInjectable(SpacesService));
        APP_MODULE.factory('FeatureFlagsService', downgradeInjectable(FeatureFlagsService));
        APP_MODULE.factory('AudienceOnboardService', downgradeInjectable(AudienceOnboardService));
        APP_MODULE.factory('LicenseService', downgradeInjectable(LicenseService));
        APP_MODULE.factory('fileSaver', downgradeInjectable(FileSaverService));
        APP_MODULE.factory('fileDownloader', downgradeInjectable(DownloadService));
        APP_MODULE.factory('PlatformStateService', downgradeInjectable(PlatformStateService));
        APP_MODULE.factory('VendorsDataService', downgradeInjectable(VendorsDataService));

        const environment = this.injector.get(ENVIRONMENT_TOKEN) as Environment;
        downgradeForAngularJS(environment);

        APP_MODULE.config(['shareOAuthProvider', (shareOAuthProvider) => {
            shareOAuthProvider.setV1BaseUrl(environment.v1BaseUrl);
            ace.config.set('basePath', environment.aceBasePath);
        }]);


        this.upgrade.bootstrap(document.querySelector('ft-account-app'), ['AccountApp.v2'], { strictDi: true });

        const spacesService = this.injector.get(SpacesService);
        const identity = this.injector.get(IdentityService);
        const stateRegistry = this.injector.get(StateRegistry);
        const areasRegistry = this.injector.get(AREAS_REGISTRY) as AreasRegistry,
            appMenuAreasRegistry = this.injector.get(APP_MENU_AREAS_REGISTRY) as AreasRegistry;

        const addStateToAreasRegistry = (state: StateRegistryItem, activeWhen?: string) => {
            if (activeWhen) {
                state.activeWhen = activeWhen;
            }
            areasRegistry.add(state);
            appMenuAreasRegistry.add(state);
        };

        if (!identity.isAnonymous()) {
            if (identity.isFliptoStaff()) {
                ADMIN_STATES.forEach(state => {
                    stateRegistry.register(state);
                });
            }

            switch (spacesService.current?.type) {
                case SpaceType.Company: {
                    COMPANY_SPACE_STATES.forEach(state => {
                        stateRegistry.register(state);
                    });

                    addStateToAreasRegistry(
                        new StateRegistryItem('Account settings', 'ft-navitem', 'account-settings', getSpaceAttributesFromSpace(spacesService.current), 10),
                        'account-settings.**'
                    );
                    addStateToAreasRegistry(
                        new StateRegistryItem('Discovery', 'ft-navitem', 'discovery.big-picture', getSpaceAttributesFromSpace(spacesService.current), 2, true),
                        'discovery.**'
                    );
                    addStateToAreasRegistry(
                        new StateRegistryItem('Team', 'ft-navitem', 'team', getSpaceAttributesFromSpace(spacesService.current), 6),
                        'team.**'
                    );
                    break;
                }
                case SpaceType.Property: {
                    PROPERTY_SPACE_STATES.forEach(state => {
                        stateRegistry.register(state);
                    });

                    addStateToAreasRegistry(
                        new StateRegistryItem('Discovery', 'ft-navitem', 'discovery.big-picture', getSpaceAttributesFromSpace(spacesService.current), 2),
                        'discovery.**'
                    );
                    addStateToAreasRegistry(
                        new StateRegistryItem('Incentives', 'ft-navitem', 'incentives', getSpaceAttributesFromSpace(spacesService.current), 6),
                        'incentives.**'
                    );
                    break;
                }
                case SpaceType.Mixed: {
                    MIXED_SPACE_STATES.forEach(state => {
                        stateRegistry.register(state);
                    });

                    addStateToAreasRegistry(
                        new StateRegistryItem('Discovery', 'ft-navitem', 'discovery.big-picture', getSpaceAttributesFromSpace(spacesService.current), 2),
                        'discovery.**'
                    );

                    addStateToAreasRegistry(
                        new StateRegistryItem('Account settings', 'ft-navitem', 'account-settings', getSpaceAttributesFromSpace(spacesService.current), 10),
                        'account-settings.**'
                    );
                    addStateToAreasRegistry(
                        new StateRegistryItem('Incentives', 'ft-navitem', 'incentives', getSpaceAttributesFromSpace(spacesService.current), 6),
                        'incentives.**'
                    );
                    addStateToAreasRegistry(
                        new StateRegistryItem('Team', 'ft-navitem', 'team', getSpaceAttributesFromSpace(spacesService.current), 7),
                        'team.**'
                    );

                    break;
                }
                case SpaceType.Collaborative: {
                    COLLABORATIVE_COMPANY_SPACE_STATES.forEach(state => {
                        stateRegistry.register(state);
                    });

                    const moreSubStates = [];

                    if (identity.isFliptoStaff()) {
                        const state = new StateRegistryItem('Collaborative settings', 'ft-navitem', 'account-settings', getSpaceAttributesFromSpace(spacesService.current), 6);
                        appMenuAreasRegistry.add(state);
                        moreSubStates.push(state);
                    }

                    if (identity.isFliptoStaff()) {
                        const membersState = new StateRegistryItem('Members', 'ft-navitem', 'members', getSpaceAttributesFromSpace(spacesService.current), 5);
                        appMenuAreasRegistry.add(membersState);
                        moreSubStates.push(membersState);
                    }

                    if (identity.isFliptoStaff() && identity.hasPermission(Permissions.UserManagementRead)) {
                        const state = new StateRegistryItem('Team', 'ft-navitem', 'team', getSpaceAttributesFromSpace(spacesService.current), 4);
                        appMenuAreasRegistry.add(state);
                        moreSubStates.push(state);
                    }

                    if (moreSubStates.length > 0) {
                        areasRegistry.add(new MultipleOptionsRegistryItem('Membership', 'ft-navitem', moreSubStates, 4));
                    }


                    const states = [
                        new StateRegistryItem(
                            'Overview',
                            'ft-navitem',
                            'collaborative.overview',
                            getSpaceAttributesFromSpace(spacesService.current),
                            1),
                        new StateRegistryItem(
                            'Insights',
                            'ft-navitem',
                            'collaborative.insights',
                            getSpaceAttributesFromSpace(spacesService.current),
                            2),
                        new StateRegistryItem(
                            'Resources',
                            'ft-navitem',
                            'collaborative.resources',
                            getSpaceAttributesFromSpace(spacesService.current),
                            3,
                            true)
                    ];
                    states.forEach(state => {
                        areasRegistry.add(state);
                        appMenuAreasRegistry.add(state);
                    });
                    break;
                }
            }
        }

        browserUpdate({
            required: { e: -5, f: -5, o: -5, s: -5, c: -5 },
            insecure: true,
            unsupported: true,
            api: 2020.09,
            l: "en",
            shift_page_down: false,
            nostatistics: true,
            text: { 'msgmore': 'You may experience issues using your Flip.to account on older browsers.' }
        })
    }
}
