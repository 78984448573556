import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { FooterComponent } from './footer.component';
import { FooterItemComponent } from './components/footer-item/footer-item.component';
import { MatIconModule } from '@angular/material/icon';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [CommonModule, AccountSharedModule, MatIconModule, NgbTooltip],
    declarations: [FooterComponent, FooterItemComponent],
    exports: [FooterComponent, FooterItemComponent],
})
export class FooterModule {}
