<header class="ft2-header"
        [class]="modifierClass"
        [class.-inverse]="inverse"
        [class.-matte]="matte"
        [class.-blur]="blur"
        [class.-fade-out_light]="fadeOut === 'light'"
        [class.-fade-out_dark]="fadeOut === 'dark'">
    <div class="ft2-header_background"
         [ftBackgroundImage]="backgroundImageUrl"></div>
    <div class="ft2-header_content" [class]="contentModifierClass">
        <ng-content></ng-content>
    </div>
    <ng-content select="ft-header-nav"></ng-content>
</header>
