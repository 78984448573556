import { NgModule } from '@angular/core';

import { DiscoveryStoreModule } from 'account-hybrid/features/discovery/store';

@NgModule({
    imports: [
        DiscoveryStoreModule,
    ],
})
export class RootStoresModule {
}
