import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { EventHooksService } from './services/event-hooks.service';
import { IconsService } from './services/icons.service';
import { AppMenuModule } from './components/app-menu/app-menu.module';
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';
import { NavModule } from './components/navigation/navigation.module';
import { NotificationsModule } from './components/notifications/notifications.module';
import { SpacesModule } from './components/spaces/spaces.module';
import { TouchpointsModule } from './components/touchpoints/touchpoints.module';
import { PermissionsModule } from './components/permissions/permissions.module';
import { CommonDiscoveryModule } from './components/discovery/common-discovery.module';
import { AudienceOnboardModule } from './modules/audience-onboard/audience-onboard.module';
import { CommonDeclarationsModule } from './modules/common-declarations/common-declarations.module';
import { CommonGridModule } from './components/grid/commongrid.module';
import { ImageUploaderService } from './services/image-uploader.service';
import { NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportBadgeModule } from './components/report-badge/report-badge.module';
import { ShowcaseStoriesModule } from 'account-hybrid/common/components/showcase-stories/showcase-stories.module';

@NgModule({
    imports: [
        CommonModule,
        AccountSharedModule,
        CommonDeclarationsModule,
        AppMenuModule,
        HeaderModule,
        NavModule,
        NotificationsModule,
        SpacesModule,
        TouchpointsModule.forRoot(),
        PermissionsModule,
        CommonDiscoveryModule,
        AudienceOnboardModule,
        CommonGridModule,
        NgbTooltipModule,
        ReportBadgeModule,
        ShowcaseStoriesModule
    ],
    providers: [
        EventHooksService,
        IconsService,
        ImageUploaderService
    ],
    exports: [
        CommonModule,
        AccountSharedModule,
        AppMenuModule,
        HeaderModule,
        FooterModule,
        NavModule,
        NotificationsModule,
        SpacesModule,
        TouchpointsModule,
        PermissionsModule,
        CommonDiscoveryModule,
        CommonDeclarationsModule,
        CommonGridModule,
        ReportBadgeModule,
        ShowcaseStoriesModule
    ],
})
export class AccountCommonModule {
    constructor(config: NgbTooltipConfig) {
        config.triggers = 'hover focus';
        config.autoClose = 'outside';
        config.placement = 'top';
        config.tooltipClass = 'tooltip-small';
    }
}
