import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { DiscoveryEffects } from './discovery.effects';
import { discoveryReducer } from './discovery.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('discovery', discoveryReducer),
        EffectsModule.forFeature([DiscoveryEffects]),
    ],
})
export class DiscoveryStoreModule {
}
